import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/nourishArtsCulture/dashboard'));
const ReportPersonnel = lazy(() => import('../../container/nourishArtsCulture/reports/personnel.js'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="report-personnel" element={<ReportPersonnel />} />
      <Route path="*" element={<Navigate to="/nourish-arts-culture/dashboard" />} />
    </Routes>
  );
}

export default PagesRoute;
