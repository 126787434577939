import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/management/training'));
// const Add = lazy(() => import('../../container/management/training/add'));
// const Update = lazy(() => import('../../container/management/training/update'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      {/* <Route path="/add" element={<Add />} />
      <Route path="/update" element={<Update />} /> */}
      <Route path="*" element={<Navigate to="/management/training" />} />
    </Routes>
  );
}

export default PersonnelRoute;
