import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from "./pages";
import Personnel from './personnel';
import Student from './student';
import ArtsCulture from './artsCulture';
import ArtsCultureStrategicDevelopment from './artsCultureStrategicDevelopment';
import withAdminLayout from '../../layout/nourishArtsCulture/withAdminLayout';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route index path="/personnel/*" element={<Personnel />} />
        <Route index path="/student/*" element={<Student />} />
        <Route index path="/arts-culture/*" element={<ArtsCulture />} />
        <Route index path="/arts-culture-strategic-development/*" element={<ArtsCultureStrategicDevelopment />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
