import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from './pages';
import PeopleWork from './peopleWork';
import PeopleYear from './peopleYear';
import Personnel from './personnel';
import Education from './education';
import Vacation from './vacation';
import Training from './training';
import SelfDevelopment from './selfDevelopment';
import withAdminLayout from '../../layout/management/withAdminLayout';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route index path="/people-work/*" element={<PeopleWork />} />
        <Route index path="/people-year/*" element={<PeopleYear />} />
        <Route index path="/personnel/*" element={<Personnel />} />
        <Route index path="/personnel/edu/*" element={<Education />} />
        <Route index path="/vacation/*" element={<Vacation />} />
        <Route index path="/training/*" element={<Training />} />
        <Route index path="/self-development/*" element={<SelfDevelopment />} />
        {/* <Route path="pages/*" element={<Pages />} /> */}
        {/* Remove director, performanceAppraisal, positionAppraisal */}
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
