import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/academic/course'));

const View = lazy(() => import('../../container/academic/course/subjectType'));
const ViewAdd = lazy(() => import('../../container/academic/course/subjectType/add'));
const ViewUpdate = lazy(() => import('../../container/academic/course/subjectType/update'));

const SubjectList = lazy(() => import('../../container/academic/course/subject'));
const SubjectListAdd = lazy(() => import('../../container/academic/course/subject/add'));
const SubjectListUpdate = lazy(() => import('../../container/academic/course/subject/update'));

const Subject = lazy(() => import('../../container/academic/course/syllabus'));
const SubjectAdd = lazy(() => import('../../container/academic/course/syllabus/add'));
const SubjectUpdate = lazy(() => import('../../container/academic/course/syllabus/update'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/view" element={<View />} />
      <Route path="/view/add" element={<ViewAdd />} />
      <Route path="/view/update" element={<ViewUpdate />} />

      <Route path="/view/subject-list" element={<SubjectList />} />
      <Route path="/view/subject-list/add" element={<SubjectListAdd />} />
      <Route path="/view/subject-list/update" element={<SubjectListUpdate />} />

      <Route path="/view/subject-list/subject" element={<Subject />} />
      <Route path="/view/subject-list/subject/add" element={<SubjectAdd />} />
      <Route path="/view/subject-list/subject/update" element={<SubjectUpdate />} />

      <Route path="*" element={<Navigate to="/academic/course" />} />
    </Routes>
  );
}

export default PersonnelRoute;
