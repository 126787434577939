import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/academic/subjectAssessment'));
const View = lazy(() => import('../../container/academic/subjectAssessment/view'));
const Detail = lazy(() => import('../../container/academic/subjectAssessment/detail'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/view" element={<View />} />
      <Route path="/view/detail" element={<Detail />} />
      <Route path="*" element={<Navigate to="/academic/subject-assessment" />} />
    </Routes>
  );
}

export default PersonnelRoute;
