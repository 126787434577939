import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/supplies/reports'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/supplies/durable-articles" />} />
    </Routes>
  );
}

export default PagesRoute;
