import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from './pages';
import ManageSubjects from "./manageSubjects";
import Course from "./course";
import AssessmentList from "./assessmentList";
import SubjectAssessment from "./subjectAssessment";
import Schedule from "./schedule";
import Graduating from "./graduating";
import TeachingWorkload from "./teachingWorkload";
import withAdminLayout from '../../layout/academic/withAdminLayout';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route index path="/manage-subjects/*" element={<ManageSubjects />} />
        <Route index path="/course/*" element={<Course />} />
        <Route index path="/assessment-list/*" element={<AssessmentList />} />
        <Route index path="/subject-assessment/*" element={<SubjectAssessment />} />
        <Route index path="/schedule/*" element={<Schedule />} />
        <Route index path="/graduating/*" element={<Graduating />} />
        <Route index path="/teaching-workload/*" element={<TeachingWorkload />} />
        {/* <Route path="pages/*" element={<Pages />} /> */}
        {/* Remove working, workload: {academic, research, seminar}, positionAppraisal,  course/syllabus: {detail, addMCO, addPlan} */}
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
