import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const List = lazy(() => import('../../container/nourishArtsCulture/personnel'));
const Add = lazy(() => import('../../container/nourishArtsCulture/personnel/add'));
const Update = lazy(() => import('../../container/nourishArtsCulture/personnel/update'));

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path="add" element={<Add />} />
      <Route path="update" element={<Update />} />
      <Route path="*" element={<Navigate to="/nourish-arts-culture/dashboard" />} />
    </Routes>
  );
}

export default PagesRoute;
