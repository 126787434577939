import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/academic/graduating'));
const View = lazy(() => import('../../container/academic/graduating/view'));
const Add = lazy(() => import('../../container/academic/graduating/add'));
const AddAdmission = lazy(() => import('../../container/academic/graduating/addAdmissionType'));
const Update = lazy(() => import('../../container/academic/graduating/update'));
const UpdateAdmission = lazy(() => import('../../container/academic/graduating/updateAdmissionType'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/view" element={<View />} />
      <Route path="/view/add" element={<Add />} />
      <Route path="/view/addAdmission" element={<AddAdmission />} />
      <Route path="/view/update" element={<Update />} />
      <Route path="/view/updateAdmission" element={<UpdateAdmission />} />
      <Route path="*" element={<Navigate to="/academic/graduating" />} />
    </Routes>
  );
}

export default PersonnelRoute;
