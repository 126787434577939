import actions from './actions';
import { AuthService } from '../../config/dataService/dataService';
import { removeItem, setItem } from "../../utility/localStorageControl";

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback, errCallback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    // dispatch({ type: actions.LOGIN_BEGIN, });
    try {
      const response = await AuthService.post('/auth/login', values);
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        const auth = response.data.result;
        setItem('_BNS', auth);
        dispatch(loginSuccess(auth));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
      removeItem("_BNS");
      errCallback();
      // dispatch({ type: actions.LOGIN_ERR, err, });
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await AuthService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      removeItem("_BNS");
      dispatch(logoutSuccess(undefined));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };
