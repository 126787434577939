import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/management/personnel'));
const Add = lazy(() => import('../../container/management/personnel/add'));
const Update = lazy(() => import('../../container/management/personnel/update'));
const AddSupport = lazy(() => import('../../container/management/personnel/addSupport'));
const UpdateSupport = lazy(() => import('../../container/management/personnel/updateSupport'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/add" element={<Add />} />
      <Route path="/addSupport" element={<AddSupport />} />
      <Route path="/update" element={<Update />} />
      <Route path="/updateSupport" element={<UpdateSupport />} />
      <Route path="*" element={<Navigate to="/management/personnel" />} />
    </Routes>
  );
}

export default PersonnelRoute;
