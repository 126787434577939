import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/management/selfDevelopment'));
const Add = lazy(() => import('../../container/management/selfDevelopment/add'));
const Update = lazy(() => import('../../container/management/selfDevelopment/update'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/add" element={<Add />} />
      <Route path="/update" element={<Update />} />
      <Route path="*" element={<Navigate to="/management/self-development" />} />
    </Routes>
  );
}

export default PersonnelRoute;
