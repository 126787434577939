import { UilCircle, UilChartPieAlt, UilAnalytics, UilFileGraph, UilAlignJustify, UilPresentationCheck } from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import NavigationModal from "../NavigationModal";
import { ROLE } from "../../utility";

function MenuItems({ toggleCollapsed }) {

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu, auth } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
      auth: state.auth.user,
    };
  });

  const path = '/management';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const [selectedKeys, setSelectedKeys] = React.useState(mainPathSplit.length >= 2 ? mainPathSplit[1] : mainPathSplit[2]);
  const [stateModal, setStateModal] = useState(false);

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  useEffect(() => {
    setSelectedKeys(mainPathSplit.filter(x => !["add", "update"].includes(x)).at(-1));
  }, [mainPathSplit]);
  const menuList = [{
    key: "dashboard",
    name: "แดชบอร์ด",
    icon: <UilCircle />
  }, {
    key: "people",
    name: "เมนู ปฏิบัติงาน",
    icon: <UilPresentationCheck />,
    children: [{
      key: "people-year",
      name: "ปีปฏิบัติ",
    }, {
      key: "people-work",
      name: "ปฏิบัติงาน",
    }
    ]
  }, {
    key: "management",
    name: "เมนู ด้านบริหาร",
    icon: <UilAnalytics />,
    children: [{
      key: "personnel",
      name: "บุคลากร",
    }, {
      key: "vacation",
      name: "การลา",
    }, {
      key: "self-development",
      name: "การพัฒนาตนเอง",
    }
      // {
      //   key: "training",
      //   name: "ฝึกอบรม",
      // }
    ]
  }, {
    key: "report",
    name: "เมนูสรุป ด้านบริหาร",
    icon: <UilFileGraph />,
    children: [{
      key: "report-self-development",
      name: "สรุปพัฒนาตนเอง",
    }, {
      key: "report-vacation",
      name: "สรุปวันลา",
    }, {
      key: "report-professor",
      name: "รายชื่อบุคลากร สายวิชาการ",
    }, {
      key: "report-personnel",
      name: "รายชื่อบุคลากร สายสนับสนุน",
    }, {
      key: "report-perform",
      name: "ปฏิทินปฏิบัติงานราชการ",
    }]
  }];
  const renderSubMenu = (item) => (
    getItem(
      (item.children
        ? (item.name)
        : (<NavLink onClick={() => { toggleCollapsed(); setSelectedKeys(item.key); }} to={`${path}/${item.key}`}>
          {item.name}
        </NavLink>)
      ),
      item.key,
      !topMenu && (
        (item.children
          ? (<UilCircle style={{ color: "transparent" }} />)
          : (<NavLink className="menuItem-iocn" onClick={() => { setSelectedKeys(item.key); }} to={`${path}/${item.key}`}>
            <UilCircle />
          </NavLink>)
        )
      ),
      (item.children
        ? item.children.map((subList, sKey) => (
          getItem(
            <NavLink onClick={() => { setSelectedKeys(subList.key); }} to={`${path}/${item.key}/${subList.key}`}>
              <UilCircle style={{ color: "transparent" }} />{subList.name}
            </NavLink>,
            subList.key,
          )
        ))
        : null
      )
    )
  );
  const items = menuList.map((item, key) => {
    return getItem(
      (item.children
        ? (item.name)
        : (<NavLink onClick={() => { toggleCollapsed(); setSelectedKeys(item.key); }} to={`${path}/${item.key}`}>
          {item.name}
        </NavLink>)
      ),
      item.key,
      !topMenu && (
        (item.children
          ? (item.icon)
          : (<NavLink className="menuItem-iocn" onClick={() => { setSelectedKeys(item.key); }} to={`${path}/${item.key}`}>
            <UilChartPieAlt />
          </NavLink>)
        )
      ),
      (item.children
        ? item.children.map((subList, sKey) => {
          if (subList?.children) {
            return renderSubMenu(subList);
          } else {
            return getItem(
              <NavLink onClick={() => { setSelectedKeys(subList.key); }} to={`${path}/${subList.key}`}>
                {subList.name}
              </NavLink>,
              subList.key,
            );
          }
        }
        )
        : null
      )
    );
  });
  if (ROLE.ADMINISTRATOR.includes(auth.role_id)) {
    items.unshift(getItem(
      <span style={{ width: "100%", color: "#404040" }} onClick={() => { setStateModal(true); }}>
        ตัวเลือกเมนูอื่นๆ
      </span>,
      'navigation',
      <UilAlignJustify />,
    ));
  }
  return (
    <>
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        // // eslint-disable-next-line no-nested-ternary
        defaultSelectedKeys={
          !topMenu
            ? [
              `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length >= 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
            : []
        }
        defaultOpenKeys={['people', 'management', 'report']}
        selectedKeys={selectedKeys}
        // defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<UilEllipsisV />}
        // openKeys={openKeys}
        items={items}
      />
      <NavigationModal active={path} isShow={stateModal} onClose={(status) => setStateModal(status)} />
    </>
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
