import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/academic/workload/teaching'));
const Detail = lazy(() => import('../../container/academic/workload/teachingDetail'));
// const Add = lazy(() => import('../../container/academic/teachingWorkload/add'));
// const Update = lazy(() => import('../../container/academic/teachingWorkload/update'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="detail" element={<Detail />} />
      <Route path="*" element={<Navigate to="/academic/teaching-workload" />} />
    </Routes>
  );
}

export default PersonnelRoute;
