import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROLE_SUPPLIES } from '../../utility';

function ProtectedRoutManagement({ Component, path }) {
  const { user: auth } = useSelector((state) => state.auth);

  const isDISALLOW = ROLE_SUPPLIES.DISALLOW.find((x) => x.ROLE.includes(auth.role_id));
  if (isDISALLOW) {
    return <Navigate to={isDISALLOW.REDIRECT} />;
  }

  return auth ? (
    <Routes>
      <Route element={<Component />} path={path} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/finance" element={<Navigate to="/" />} />
    </Routes>
  );
}

ProtectedRoutManagement.propTypes = {
  Component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoutManagement;
