import dayjs from "dayjs";

import buddhistEra from "dayjs/plugin/buddhistEra";
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import th from 'dayjs/locale/th';
import "dayjs/locale/th";

dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(buddhistEra);
dayjs.locale("th");
dayjs.locale(
  {
    ...th,
    formats: {
      LT: 'H:mm',
      LTS: 'H:mm:ss',
      L: 'DD/MM/BBBB',
      LL: 'D MMMM BBBB',
      LLL: 'D MMMM BBBB เวลา H:mm',
      LLLL: 'วันddddที่ D MMMM BBBB เวลา H:mm',
    },
  },
  null,
  true
);
