const HOME_URL = {
  ACADEMIC: process.env.REACT_APP_HOME_ACADEMIC,
  MANAGEMENT: process.env.REACT_APP_HOME_MANAGEMENT,
  ACADEMIC_SERVICE: process.env.REACT_APP_HOME_ACADEMIC_SERVICE,
  NOURISH_ARTS_CULTURE: process.env.REACT_APP_HOME_NOURISH_ARTS_CULTURE,
  RESEARCH: process.env.REACT_APP_HOME_RESEARCH,
  FINANCE: process.env.REACT_APP_HOME_FINANCE,
  SUPPLIES: process.env.REACT_APP_HOME_SUPPLIES,
};

const ROLE = {
  ADMINISTRATOR: JSON.parse(process.env.REACT_APP_ROLE_ADMINISTRATOR),
  ACADEMIC: JSON.parse(process.env.REACT_APP_ROLE_ACADEMIC),
  MANAGEMENT: JSON.parse(process.env.REACT_APP_ROLE_MANAGEMENT),
  ACADEMIC_SERVICE: JSON.parse(process.env.REACT_APP_ROLE_ACADEMIC_SERVICE),
  NOURISH_ARTS_CULTURE: JSON.parse(process.env.REACT_APP_ROLE_NOURISH_ARTS_CULTURE),
  RESEARCH: JSON.parse(process.env.REACT_APP_ROLE_RESEARCH),
  FINANCE: JSON.parse(process.env.REACT_APP_ROLE_FINANCE),
  SUPPLIES: JSON.parse(process.env.REACT_APP_ROLE_SUPPLIES),
};

const ROLE_MANAGEMENT = {
  ALLOW: [...ROLE.ADMINISTRATOR, ...ROLE.MANAGEMENT],
  HOME_URL: HOME_URL.MANAGEMENT,
  DISALLOW: [
    { ROLE: ROLE.ACADEMIC, REDIRECT: HOME_URL.ACADEMIC },
    { ROLE: ROLE.ACADEMIC_SERVICE, REDIRECT: HOME_URL.ACADEMIC_SERVICE },
    { ROLE: ROLE.NOURISH_ARTS_CULTURE, REDIRECT: HOME_URL.NOURISH_ARTS_CULTURE },
    { ROLE: ROLE.RESEARCH, REDIRECT: HOME_URL.RESEARCH },
    { ROLE: ROLE.FINANCE, REDIRECT: HOME_URL.FINANCE },
    { ROLE: ROLE.SUPPLIES, REDIRECT: HOME_URL.SUPPLIES },
  ],
};

const ROLE_ACADEMIC = {
  ALLOW: [...ROLE.ADMINISTRATOR, ...ROLE.ACADEMIC],
  HOME_URL: HOME_URL.ACADEMIC,
  DISALLOW: [
    { ROLE: ROLE.MANAGEMENT, REDIRECT: HOME_URL.MANAGEMENT },
    { ROLE: ROLE.ACADEMIC_SERVICE, REDIRECT: HOME_URL.ACADEMIC_SERVICE },
    { ROLE: ROLE.NOURISH_ARTS_CULTURE, REDIRECT: HOME_URL.NOURISH_ARTS_CULTURE },
    { ROLE: ROLE.RESEARCH, REDIRECT: HOME_URL.RESEARCH },
    { ROLE: ROLE.FINANCE, REDIRECT: HOME_URL.FINANCE },
    { ROLE: ROLE.SUPPLIES, REDIRECT: HOME_URL.SUPPLIES },
  ],
};

const ROLE_ACADEMIC_SERVICE = {
  ALLOW: [...ROLE.ADMINISTRATOR, ...ROLE.ACADEMIC_SERVICE],
  HOME_URL: HOME_URL.ACADEMIC_SERVICE,
  DISALLOW: [
    { ROLE: ROLE.ACADEMIC, REDIRECT: HOME_URL.ACADEMIC },
    { ROLE: ROLE.MANAGEMENT, REDIRECT: HOME_URL.MANAGEMENT },
    { ROLE: ROLE.NOURISH_ARTS_CULTURE, REDIRECT: HOME_URL.NOURISH_ARTS_CULTURE },
    { ROLE: ROLE.RESEARCH, REDIRECT: HOME_URL.RESEARCH },
    { ROLE: ROLE.FINANCE, REDIRECT: HOME_URL.FINANCE },
    { ROLE: ROLE.SUPPLIES, REDIRECT: HOME_URL.SUPPLIES },
  ],
};
const ROLE_NOURISH_ARTS_CULTURE = {
  ALLOW: [...ROLE.ADMINISTRATOR, ...ROLE.NOURISH_ARTS_CULTURE],
  HOME_URL: HOME_URL.NOURISH_ARTS_CULTURE,
  DISALLOW: [
    { ROLE: ROLE.ACADEMIC, REDIRECT: HOME_URL.ACADEMIC },
    { ROLE: ROLE.MANAGEMENT, REDIRECT: HOME_URL.MANAGEMENT },
    { ROLE: ROLE.ACADEMIC_SERVICE, REDIRECT: HOME_URL.ACADEMIC_SERVICE },
    { ROLE: ROLE.RESEARCH, REDIRECT: HOME_URL.RESEARCH },
    { ROLE: ROLE.FINANCE, REDIRECT: HOME_URL.FINANCE },
    { ROLE: ROLE.SUPPLIES, REDIRECT: HOME_URL.SUPPLIES },
  ],
};
const ROLE_RESEARCH = {
  ALLOW: [...ROLE.ADMINISTRATOR, ...ROLE.RESEARCH],
  HOME_URL: HOME_URL.RESEARCH,
  DISALLOW: [
    { ROLE: ROLE.ACADEMIC, REDIRECT: HOME_URL.ACADEMIC },
    { ROLE: ROLE.MANAGEMENT, REDIRECT: HOME_URL.MANAGEMENT },
    { ROLE: ROLE.ACADEMIC_SERVICE, REDIRECT: HOME_URL.ACADEMIC_SERVICE },
    { ROLE: ROLE.NOURISH_ARTS_CULTURE, REDIRECT: HOME_URL.NOURISH_ARTS_CULTURE },
    { ROLE: ROLE.FINANCE, REDIRECT: HOME_URL.FINANCE },
    { ROLE: ROLE.SUPPLIES, REDIRECT: HOME_URL.SUPPLIES },
  ],
};
const ROLE_FINANCE = {
  ALLOW: [...ROLE.ADMINISTRATOR, ...ROLE.FINANCE],
  HOME_URL: HOME_URL.FINANCE,
  DISALLOW: [
    { ROLE: ROLE.ACADEMIC, REDIRECT: HOME_URL.ACADEMIC },
    { ROLE: ROLE.MANAGEMENT, REDIRECT: HOME_URL.MANAGEMENT },
    { ROLE: ROLE.ACADEMIC_SERVICE, REDIRECT: HOME_URL.ACADEMIC_SERVICE },
    { ROLE: ROLE.NOURISH_ARTS_CULTURE, REDIRECT: HOME_URL.NOURISH_ARTS_CULTURE },
    { ROLE: ROLE.RESEARCH, REDIRECT: HOME_URL.RESEARCH },
    { ROLE: ROLE.SUPPLIES, REDIRECT: HOME_URL.SUPPLIES },
  ],
};

const ROLE_SUPPLIES = {
  ALLOW: [...ROLE.ADMINISTRATOR, ...ROLE.SUPPLIES],
  HOME_URL: HOME_URL.SUPPLIES,
  DISALLOW: [
    { ROLE: ROLE.ACADEMIC, REDIRECT: HOME_URL.ACADEMIC },
    { ROLE: ROLE.MANAGEMENT, REDIRECT: HOME_URL.MANAGEMENT },
    { ROLE: ROLE.ACADEMIC_SERVICE, REDIRECT: HOME_URL.ACADEMIC_SERVICE },
    { ROLE: ROLE.NOURISH_ARTS_CULTURE, REDIRECT: HOME_URL.NOURISH_ARTS_CULTURE },
    { ROLE: ROLE.RESEARCH, REDIRECT: HOME_URL.RESEARCH },
    { ROLE: ROLE.FINANCE, REDIRECT: HOME_URL.FINANCE },
  ],
};

const SHOW_USER_ONLY = [6, 7];
export {
  HOME_URL,
  ROLE,
  SHOW_USER_ONLY,
  ROLE_MANAGEMENT,
  ROLE_ACADEMIC,
  ROLE_ACADEMIC_SERVICE,
  ROLE_NOURISH_ARTS_CULTURE,
  ROLE_RESEARCH,
  ROLE_FINANCE,
  ROLE_SUPPLIES,
};
