import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/academic/assessmentList'));
const Detail = lazy(() => import('../../container/academic/assessmentList/detail'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      {/* BUG detail form add */}
      <Route path="/detail" element={<Detail />} />
      <Route path="*" element={<Navigate to="/academic/assessment-list" />} />
    </Routes>
  );
}

export default PersonnelRoute;
