import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import SimpleReactLightbox from 'simple-react-lightbox';
import store from './redux/store';
import Academic from './routes/academic';
import Management from './routes/management';
import Finance from './routes/finance';
import Supplies from './routes/supplies';
import AcademicService from './routes/academicService';
import NourishArtsCulture from './routes/nourishArtsCulture';
import Research from './routes/research';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoutAcademic from './components/utilities/protectedRoutAcademic';
import ProtectedRoutManagement from './components/utilities/protectedRoutManagement';
import ProtectedRoutAcademicService from './components/utilities/protectedRoutAcademicService';
import ProtectedRoutNourishArtsCulture from './components/utilities/protectedRoutNourishArtsCulture';
import ProtectedRoutResearch from './components/utilities/protectedRoutResearch';
import ProtectedRoutSupplies from './components/utilities/protectedRoutSupplies';
import ProtectedRoutFinance from './components/utilities/protectedRoutFinance';

import moment from 'moment';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import 'antd/dist/antd.less';
import './config/dayjs.config';

import {
  ROLE_ACADEMIC,
  ROLE_ACADEMIC_SERVICE,
  ROLE_FINANCE,
  ROLE_MANAGEMENT,
  ROLE_NOURISH_ARTS_CULTURE,
  ROLE_RESEARCH,
  ROLE_SUPPLIES,
} from './utility';

moment.locale('th');

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

const RedirectHome = (auth, path) => {
  if (!auth && (path !== process.env.PUBLIC_URL || path !== `${process.env.PUBLIC_URL}/`)) {
    return null;
  }
  const roles = [
    { allow: ROLE_ACADEMIC.ALLOW, homeUrl: ROLE_ACADEMIC.HOME_URL },
    { allow: ROLE_MANAGEMENT.ALLOW, homeUrl: ROLE_MANAGEMENT.HOME_URL },
    { allow: ROLE_ACADEMIC_SERVICE.ALLOW, homeUrl: ROLE_ACADEMIC_SERVICE.HOME_URL },
    { allow: ROLE_NOURISH_ARTS_CULTURE.ALLOW, homeUrl: ROLE_NOURISH_ARTS_CULTURE.HOME_URL },
    { allow: ROLE_RESEARCH.ALLOW, homeUrl: ROLE_RESEARCH.HOME_URL },
    { allow: ROLE_FINANCE.ALLOW, homeUrl: ROLE_FINANCE.HOME_URL },
    { allow: ROLE_SUPPLIES.ALLOW, homeUrl: ROLE_SUPPLIES.HOME_URL },
  ];
  const redirect = roles.find((role) => role.allow.includes(auth?.role_id))?.homeUrl || '/';
  return (
    <Routes>
      <Route path="/" element={<Navigate to={redirect} />} />
    </Routes>
  );
};

function ProviderConfig() {
  const { rtl, auth, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      auth: state.auth.user,
    };
  });
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={thTH}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <SimpleReactLightbox>
          <Router basename={process.env.PUBLIC_URL}>
            {!auth ? (
              <Routes>
                <Route path="/*" element={<Auth />} />{' '}
              </Routes>
            ) : (
              <Routes>
                <Route path="/academic/*" element={<ProtectedRoutAcademic path="/*" Component={Academic} />} />
                <Route path="/management/*" element={<ProtectedRoutManagement path="/*" Component={Management} />} />
                <Route path="/finance/*" element={<ProtectedRoutFinance path="/*" Component={Finance} />} />
                <Route path="/supplies/*" element={<ProtectedRoutSupplies path="/*" Component={Supplies} />} />
                <Route
                  path="/academic-service/*"
                  element={<ProtectedRoutAcademicService path="/*" Component={AcademicService} />}
                />
                <Route
                  path="/nourish-arts-culture/*"
                  element={<ProtectedRoutNourishArtsCulture path="/*" Component={NourishArtsCulture} />}
                />
                <Route path="/research/*" element={<ProtectedRoutResearch path="/*" Component={Research} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
            {RedirectHome(auth, path)}
          </Router>
        </SimpleReactLightbox>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
