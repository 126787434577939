import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/research/dashboard'));
const List = lazy(() => import('../../container/research'));
const Add = lazy(() => import('../../container/research/add'));
const Update = lazy(() => import('../../container/research/update'));
const ReportResearch = lazy(() => import('../../container/research/reports/research'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route index element={<List />} />
      <Route path="add" element={<Add />} />
      <Route path="update" element={<Update />} />
      <Route path="report-research" element={<ReportResearch />} />
      <Route path="*" element={<Navigate to="/research/dashboard" />} />
    </Routes>
  );
}

export default PagesRoute;
