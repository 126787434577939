import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const ReportActual = lazy(() => import('../../container/finance/reports/actual'));
const ReportActualCompare = lazy(() => import('../../container/finance/reports/actualCompare'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="report-actual" element={<ReportActual />} />
      <Route path="report-actual-compare" element={<ReportActualCompare />} />
      <Route path="*" element={<Navigate to="/finance/budget-plan/forcast" />} />
    </Routes>
  );
}

export default PagesRoute;
