import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/academic/manageSubjects'));
const Add = lazy(() => import('../../container/academic/manageSubjects/add'));
const Update = lazy(() => import('../../container/academic/manageSubjects/update'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/add" element={<Add />} />
      <Route path="/update" element={<Update />} />
      <Route path="*" element={<Navigate to="/academic/manage-subjects" />} />
    </Routes>
  );
}

export default PersonnelRoute;
