import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/finance/budgetPlan'));
const Home2 = lazy(() => import('../../container/finance/budgetPlan/indexActual'));
const Add = lazy(() => import('../../container/finance/budgetPlan/add'));
const Update = lazy(() => import('../../container/finance/budgetPlan/update'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/forcast" element={<Home />} />
      <Route path="/actual" element={<Home2 />} />
      <Route path="/add" element={<Add />} />
      <Route path="/update" element={<Update />} />
      <Route path="*" element={<Navigate to="/finance/budget-plan" />} />
    </Routes>
  );
}

export default PersonnelRoute;
