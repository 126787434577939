import { Fragment, useState } from "react";
import { Modal } from "../components/modals/antd-modals";
import { Button } from "../components/buttons/buttons";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const menuList = [{
  key: "management",
  label: "ด้านบริหาร"
}, {
  key: "academic",
  label: "ด้านวิชาการ"
}, {
  key: "finance",
  label: "ด้านการเงิน"
}, {
  key: "supplies",
  label: "ครุภัณฑ์"
}, {
  key: "academic-service",
  label: "บริการวิชาการ"
}, {
  key: "nourish-arts-culture",
  label: "บำรุงศิลปและวัฒนธรรม"
}, {
  key: "research",
  label: "วิจัย"
}];

const NavigationModal = ({ active = "", isShow = false, onClose = () => { } }) => {
  return (
    <Modal
      title="ตัวเลือกเมนูอื่นๆ"
      visible={isShow}
      onCancel={() => onClose(false)}
      width={350}
      footer={[
        <Button key="close" type="primary" style={{ width: 100 }} onClick={() => onClose(false)}>ปิด</Button>
      ]}
    >
      <Row gutter={[16, 16]}>
        {menuList.map((value, key) => (
          <Fragment key={key}>
            <Col span={24} key={key}>
              <Link to={`/${value.key}`}>
                <Button
                  type="secondary"
                  disabled={active === `/${value.key}`}
                  outlined
                  block
                  onClick={() => onClose(false)}
                >
                  เมนู {value.label}
                </Button>
              </Link>
            </Col>
          </Fragment>
        ))}
      </Row>
    </Modal>
  );
};

export default NavigationModal;