import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/academicService/dashboard'));
const List = lazy(() => import('../../container/academicService'));
const Add = lazy(() => import('../../container/academicService/add'));
const Update = lazy(() => import('../../container/academicService/update'));
const ReportAcademicService = lazy(() => import('../../container/academicService/reports/academicService'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route index element={<List />} />
      <Route path="add" element={<Add />} />
      <Route path="update" element={<Update />} />
      <Route path="report-academic-service" element={<ReportAcademicService />} />
      <Route path="*" element={<Navigate to="/academic-service/dashboard" />} />
    </Routes>
  );
}

export default PagesRoute;
