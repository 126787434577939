import { UilCircle, UilChartPieAlt, UilAlignJustify, UilBookOpen } from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import NavigationModal from '../NavigationModal';
import { ROLE } from '../../utility';

function MenuItems({ toggleCollapsed }) {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu, auth } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
      auth: state.auth.user,
    };
  });

  const path = '/academic';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const [selectedKeys, setSelectedKeys] = React.useState(
    mainPathSplit.length >= 2 ? mainPathSplit[1] : mainPathSplit[2],
  );
  const [stateModal, setStateModal] = useState(false);

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  useEffect(() => {
    setSelectedKeys(mainPathSplit.length >= 2 ? mainPathSplit[1] : mainPathSplit[2]);
  }, [mainPathSplit]);
  const menuList = [
    //   {
    //   key: "dashboard",
    //   name: "แดชบอร์ด",
    //   icon: <UilChartPieAlt />
    // },
    {
      key: 'academic',
      name: 'เมนู ด้านวิชาการ',
      icon: <UilBookOpen />,
      children: [
        {
          key: 'manage-subjects',
          name: 'การจัดการเรียนการสอน',
        },
        {
          key: 'course',
          name: 'หลักสูตร / วิชา / มคอ',
        },
        {
          key: 'assessment-list',
          name: 'กำหนดรายการประเมิน ประสิทธิภาพการเรียน การสอน',
        },
        {
          key: 'subject-assessment',
          name: 'การประเมินประสิทธิภาพ การเรียนการสอน',
        },
        {
          key: 'schedule',
          name: 'ตารางเรียน',
        },
        {
          key: 'graduating',
          name: 'นิสิตรับเข้าและสำเร็จการศึกษา',
        },
      ],
    },
  ];

  const items = menuList.map((item, key) =>
    getItem(
      item.children ? (
        item.name
      ) : (
        <NavLink
          onClick={() => {
            toggleCollapsed();
            setSelectedKeys(item.key);
          }}
          to={`${path}/${item.key}`}
        >
          {item.name}
        </NavLink>
      ),
      item.key,
      !topMenu &&
        (item.children ? (
          item.icon
        ) : (
          <NavLink
            className="menuItem-iocn"
            onClick={() => {
              setSelectedKeys(item.key);
            }}
            to={`${path}/${item.key}`}
          >
            <UilChartPieAlt />
          </NavLink>
        )),
      item.children
        ? item.children.map((subList, sKey) =>
            getItem(
              <NavLink
                onClick={() => {
                  setSelectedKeys(subList.key);
                }}
                to={`${path}/${subList.key}`}
              >
                {subList.name}
              </NavLink>,
              subList.key,
            ),
          )
        : null,
    ),
  );

  if (ROLE.ADMINISTRATOR.includes(auth.role_id)) {
    items.unshift(
      getItem(
        <span
          style={{ width: '100%', color: '#404040' }}
          onClick={() => {
            setStateModal(true);
          }}
        >
          ตัวเลือกเมนูอื่นๆ
        </span>,
        'navigation',
        <UilAlignJustify />,
      ),
    );
  }
  return (
    <>
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        // // eslint-disable-next-line no-nested-ternary
        defaultSelectedKeys={
          !topMenu
            ? [
                `${
                  mainPathSplit.length === 1 ? 'home' : mainPathSplit.length >= 2 ? mainPathSplit[1] : mainPathSplit[2]
                }`,
              ]
            : []
        }
        selectedKeys={selectedKeys}
        defaultOpenKeys={['academic', 'report']}
        // defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<UilEllipsisV />}
        // openKeys={openKeys}
        items={items}
      />
      <NavigationModal active={path} isShow={stateModal} onClose={(status) => setStateModal(status)} />
    </>
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
