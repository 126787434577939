import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const BlankPage = lazy(() => import('../../container/academic/BlankPage'));
const Dashboard = lazy(() => import('../../container/academic/dashboard'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/academic/manage-subjects" />} />
    </Routes>
  );
}

export default PagesRoute;
