import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import('../../container/management/vacation'));
const History = lazy(() => import('../../container/management/vacation/history'));
const Add = lazy(() => import('../../container/management/vacation/add'));
const Update = lazy(() => import('../../container/management/vacation/update'));

function PersonnelRoute() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/history" element={<History />} />
      <Route path="/add" element={<Add />} />
      <Route path="/update" element={<Update />} />
      <Route path="*" element={<Navigate to="/management/vacation" />} />
    </Routes>
  );
}

export default PersonnelRoute;
