import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/management/dashboard'));
const ReportSubjects = lazy(() => import('../../container/management/reports/subjects'));
const ReportSubjectDetail = lazy(() => import('../../container/management/reports/subjectDetail'));
const ReportSelfDevelopment = lazy(() => import('../../container/management/reports/selfDevelopment'));
const ReportVacation = lazy(() => import('../../container/management/reports/vacation'));
const ReportProfessor = lazy(() => import('../../container/management/reports/professor'));
const ReportPersonnel = lazy(() => import('../../container/management/reports/personnel'));
const ReportPerform = lazy(() => import('../../container/management/reports/perform'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="report-subjects" element={<ReportSubjects />} />
      <Route path="report-subjects/detail" element={<ReportSubjectDetail />} />
      <Route path="report-self-development" element={<ReportSelfDevelopment />} />
      <Route path="report-vacation" element={<ReportVacation />} />
      <Route path="report-professor" element={<ReportProfessor />} />
      <Route path="report-personnel" element={<ReportPersonnel />} />
      <Route path="report-perform" element={<ReportPerform />} />
      <Route path="*" element={<Navigate to="/management/dashboard" />} />
    </Routes>
  );
}

export default PagesRoute;
